import React from 'react'
import PropTypes from 'prop-types'
import { productPropTypes } from 'Types'
import { isPriceZero, currencyFormatter } from 'Utils'

const ProductPrice = props => {
  const {
    prices: {
      raw,
      list: listPrice,
      currency: { code }
    },
    isDiscounted = false,
    isStocked = true,
    /* FEATURES */
    showBothPrices = true,
    /* SCHEMA.ORG */
    /* SYSTEM */
    className = '',
    style = {}
  } = props

  let priceAmount =
    (raw !== null && currencyFormatter(code, raw)) ||
    (listPrice !== null && currencyFormatter(code, listPrice)) ||
    '0.00'

  const SchemaAvailabilityHref = isStocked
    ? 'http://schema.org/InStock'
    : 'http://schema.org/OutOfStock'
  return (
    <div
      className={`price ${className}`}
      {...{ style }}
      itemScope
      itemProp="offers"
      itemType="http://schema.org/Offer"
    >
      <span
        className={`${isDiscounted ? 'discounted' : ''} price`}
        itemProp="price"
        content={priceAmount}
      >
        {`${priceAmount}`}
      </span>
      <link itemProp="availability" href={SchemaAvailabilityHref} />
      <span itemProp="priceCurrency" content={code} />
      {showBothPrices && isDiscounted && !isPriceZero(listPrice) && (
        <span className={`${isDiscounted ? 'discounted' : ''} original-price`}>
          {`${currencyFormatter(code, listPrice)}`}
        </span>
      )}
    </div>
  )
}
ProductPrice.displayName = 'ProductPrice'

ProductPrice.propTypes = {
  className: PropTypes.string,
  showBothPrices: PropTypes.bool,
  style: PropTypes.object,
  ...productPropTypes
}

export default ProductPrice
