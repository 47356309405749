import { css } from '@emotion/core'
import theme from 'Theme'

const { typography, colors } = theme

export default ({ isDiscounted }) => css`

  .original-price {
    display: inline-block;
    margin-left: 1rem;
    text-decoration: line-through;
    color: ${colors.discountedPrice};
  }
`
