import { css } from '@emotion/core'

export default () => css`
.hero-banner {
  width: 100%;
  height: 730px;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.hero-banner .banner-container {
  width: 1044px;
  margin: 0 auto;
}
.hero-banner .banner-content-section {
  width: 440px;
  color: #fff; 
}

.hero-banner .banner-title {
  margin-bottom: 8px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.1px; 
}
.hero-banner .banner-heading {
  margin-bottom: 16px;
  font-family: "Recife";
  font-weight: 300;
  font-size: 64px;
  line-height: 72px; 
}
  
.hero-banner .banner-header-title {
  margin-bottom: 26px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.64px;
}

.hero-banner .banner-link {
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 1.5px;
  text-transform: uppercase; 
}

.hero-banner .banner-link:hover {
  text-decoration: none;
}
  
@media (max-width: 767px) {
  .hero-banner {
    height: 680px;
    -webkit-align-items: end;
    -moz-align-items: end;
    -ms-align-items: end;
    align-items: end;
  }
  
  .hero-banner .banner-container {
    width: 100%;
  }
      
  .hero-banner .banner-content-section {
    width: 100%;
    padding: 0 25px;
  }
      
  .hero-banner .banner-heading {
    font-size: 45px;
    line-height: 50px;
  }
      
  .hero-banner .banner-header-title {
    font-size: 12px;
    line-height: 18px;
  }
}
  
@media (min-width: 768px) and (max-width: 1023px) {
  .hero-banner {
    padding: 0 50px;
  }
}
  
@media (min-width: 1024px) and (max-width: 1399px) {
  .hero-banner {
    padding: 0 40px;
    background-position: right center;
  }
}

.image-content-section {
  padding: 60px 0;
  color: #fff;
  background: #0F085B; 
}
  
.image-content-section.reverse-content .section-content-holder {
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
  
.image-content-section.reverse-content .section-content-block {
  padding-left: 0;
  padding-right: 130px;
}
  
.image-content-section .section-content-holder {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.image-content-section .section-image-block {
  width: 480px;
  height: 510px;
}

.image-content-section .section-content-block {
  width: calc(100% - 480px);
  padding-left: 130px;
}

.image-content-section .content-tag {
  margin-bottom: 20px;
}

.image-content-section .section-title {
  font-family: "Recife";
  font-size: 45px;
  line-height: 52px;
}

.image-content-section .section-description {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.64px; 
}
  
.image-content-section .section-button-row {
  margin-top: 40px;
}
  
@media (max-width: 767px) {
  .image-content-section {
    padding: 40px 0;
  }

  .image-content-section .section-image-block {
    width: 100%;
    height: 350px;
  }

  .image-content-section .section-image-block .global-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
      
  .image-content-section .section-content-block {
    width: 100%;
    padding-top: 35px;
    padding-right: 50px;
    padding-left: 0;
  }

  .image-content-section .content-tag {
    margin-bottom: 12px;
  }
      
  .image-content-section .section-title {
    font-size: 30px;
    line-height: 38px;
  }

  .image-content-section .section-description {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
      
  .image-content-section .section-button-row {
    margin-top: 30px;
  }
}
    
@media (min-width: 768px) and (max-width: 1023px) {
  .image-content-section {
    padding: 40px 0;
  }
      
  .image-content-section .section-image-block {
    width: 280px;
    height: 300px;
  }
      
  .image-content-section .section-content-block {
    width: calc(100% - 280px);
    padding-left: 30px;
  }
      
  .image-content-section .section-title {
    font-size: 30px;
    line-height: 38px;
  }

  .image-content-section .section-description {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
      
  .image-content-section .section-button-row {
    margin-top: 30px;
  }
}
    
@media (min-width: 1024px) and (max-width: 1399px) {
  .image-content-section {
    padding: 40px;
  }
      
  .image-content-section .section-content-block {
    padding-left: 80px;
  }
}

.hero-banner .hero-banner-text {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          font-size: 45px;
          color: #fff;
          font-weight: 400;
          text-transform: uppercase;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -moz-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -ms-flex-pack: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          -webkit-content: stretch;
          -moz-content: stretch;
          -ms-content: stretch;
          content: stretch;
      }
      
      .hero-banner.video-header {
          position: relative;
        }
`
